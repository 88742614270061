import React from "react";
import styled, { ThemeProps } from "styled-components";

import { ContentLayoutThemeInterface } from "../content-layout/types";
import { HeadingBlockInterface } from "./types";
import { AnchorLink, StyledLink } from "../../anchor-links";

export const ContentHeadingBlock = styled.div`
  line-height: 1.4;
  position: relative;
  grid-column: ${(props: ThemeProps<ContentLayoutThemeInterface>) =>
    props.theme.gridColumnDesktop};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2rem;
  }

  h1 {
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 900;
    margin: 0 0 1rem;
  }

  &:hover {
    ${StyledLink} {
      opacity: 0.9;
    }
  }
`;

export const HeadingBlock: React.FunctionComponent<HeadingBlockInterface> = (
  props: HeadingBlockInterface
) => {
  const { headingText, headingType } = props;
  const Heading = headingType;
  return (
    <ContentHeadingBlock>
      <AnchorLink anchorText={headingText} />
      <Heading>{headingText}</Heading>
    </ContentHeadingBlock>
  );
};

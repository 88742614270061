import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { BreadCrumbsInterface, BreadCrumbItemInterface } from "./types";

const BreadCrumbItem = styled.span``;

const BreadCrumbItemLink = styled(Link)`
  color: var(--brand);
  text-decoration: none;
`;

const BreadCrumbWrapper = styled.div`
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;

  ${BreadCrumbItem},
  ${BreadCrumbItemLink} {
    &:after {
      content: "/";
      color: #ccc;
      display: inline-block;
      padding: 0 0.5rem;
    }

    &:last-child {
      /* color: #aaa; */

      /* &:after {
        content: '';
      } */
    }
  }
`;

export const BreadCrumbs: React.FunctionComponent<BreadCrumbsInterface> = (
  props: BreadCrumbsInterface
) => {
  const { crumbs } = props;
  const defaultCrumb = {
    label: "Home",
    url: "/",
  };

  return (
    <BreadCrumbWrapper>
      {[defaultCrumb, ...(crumbs as BreadCrumbItemInterface[])].map(
        (breadCrumbItem) => (
          <React.Fragment key={breadCrumbItem.label}>
            {breadCrumbItem.url ? (
              <BreadCrumbItemLink to={breadCrumbItem.url}>
                {breadCrumbItem.label}
              </BreadCrumbItemLink>
            ) : (
              <BreadCrumbItem>{breadCrumbItem.label}</BreadCrumbItem>
            )}
          </React.Fragment>
        )
      )}
    </BreadCrumbWrapper>
  );
};

BreadCrumbs.defaultProps = {
  crumbs: [],
};

import React from "react";
import styled from "styled-components";
import { GridContainerInterface, ViewportSize, GridArea } from "./types";

const gridColumnsTablet = `1fr 1fr 48px repeat(2, minmax(200px, 340px)) 48px 1fr 1fr`;
const gridColumnsDesktop = `1fr 1fr 128px repeat(2, minmax(300px, 340px)) 128px 1fr 1fr`;

const StyledGridContainer = styled.div`
  padding: 0 1rem;
  margin: 0;

  @media (min-width: ${ViewportSize.TABLET}px) {
    padding: 0;
    display: grid;
    grid-template-columns: ${gridColumnsTablet};
    grid-template-areas:
      ". . . ${GridArea.CONTENT} ${GridArea.CONTENT} . . ."
      ". . . ${GridArea.CONTENT_LEFT} ${GridArea.CONTENT_RIGHT} . . ."

      ". . ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} . ."
      ". . ${GridArea.CONTENT_BLEED_LEFT} ${GridArea.CONTENT_BLEED_LEFT} ${GridArea.CONTENT_BLEED_RIGHT} ${GridArea.CONTENT_BLEED_RIGHT} . ."

      "${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN}"

      "${GridArea.PULLOUT_LEFT} ${GridArea.PULLOUT_LEFT} ${GridArea.PULLOUT_LEFT} . . ${GridArea.PULLOUT_RIGHT} ${GridArea.PULLOUT_RIGHT} ${GridArea.PULLOUT_RIGHT}"

      "${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} . . ."
      ". . . ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED}";

    grid-column-gap: 1rem;
  }

  @media (min-width: ${ViewportSize.DESKTOP}px) {
    padding: 0;
    display: grid;
    grid-template-columns: ${gridColumnsDesktop};
    grid-template-areas:
      ". . . ${GridArea.CONTENT} ${GridArea.CONTENT} . . ."
      ". . . ${GridArea.CONTENT_LEFT} ${GridArea.CONTENT_RIGHT} . . ."

      ". . ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} ${GridArea.CONTENT_BLEED} . ."
      ". . ${GridArea.CONTENT_BLEED_LEFT} ${GridArea.CONTENT_BLEED_LEFT} ${GridArea.CONTENT_BLEED_RIGHT} ${GridArea.CONTENT_BLEED_RIGHT} . ."

      "${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN}"

      "${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN} ${GridArea.FULL_SCREEN}"

      "${GridArea.PULLOUT_LEFT} ${GridArea.PULLOUT_LEFT} ${GridArea.PULLOUT_LEFT} . . ${GridArea.PULLOUT_RIGHT} ${GridArea.PULLOUT_RIGHT} ${GridArea.PULLOUT_RIGHT}"

      "${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} ${GridArea.LEFT_ALIGNED} . . ."
      ". . . ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED} ${GridArea.RIGHT_ALIGNED}";

    grid-column-gap: 1rem;
  }
`;

export const GridContainer: React.FunctionComponent<GridContainerInterface> = (
  props: GridContainerInterface
) => {
  const { children } = props;
  return <StyledGridContainer>{children}</StyledGridContainer>;
};

import React, { useEffect, useRef, useMemo } from "react";
import styled, { ThemeProps } from "styled-components";
import SvgLink from "../../images/link.svg";

export const StyledLink = styled.a`
  display: flex;
  align-items: center;
  position: absolute;
  left: -2rem;
  top: 2rem;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: opacity 0.3s ease;
  &:focus {
    opacity: 0.9;
  }
`;

export const StyledLinkImage = styled.img`
  width: 1em;
`;

export const generateAnchorId = (anchorText: string): string => {
  const rgx = /\W|(_)/g;
  return anchorText.toLowerCase().replace(rgx, "-");
};

interface AnchorLinkInterface {
  anchorText: string;
}

export const AnchorLink = (props: AnchorLinkInterface) => {
  const { anchorText } = props;
  const jumpLink = useMemo(() => generateAnchorId(anchorText), [anchorText]);
  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  useEffect(() => {
    if (window.location.hash === `#${jumpLink}`) {
      if (anchorRef.current) {
        const top = anchorRef.current.getBoundingClientRect().top;
        window.scrollTo(0, top);
      }
    }
  }, []);

  return (
    <StyledLink ref={anchorRef} href={`#${jumpLink}`} id={jumpLink}>
      <StyledLinkImage src={SvgLink} alt="Link" />
    </StyledLink>
  );
};

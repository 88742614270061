import React, { FormEvent, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Seo } from "../components/seo";
import { LayoutDefault } from "../layouts/default";
import { ContentSection } from "../components/content-blocks/text-block";
import { SiteMetadataInterface } from "../types/site-metadata";
import { GridContainer } from "../components/grid/content-blocks/container";
import { GridRow } from "../components/grid/content-blocks/row";
import { BreadCrumbs } from "../components/breadcrumbs";

interface QueryData {
  data: {
    site: {
      siteMetadata: SiteMetadataInterface;
    };
  };
}

const ContactContent = styled.section`
  ul {
    list-style: none;

    li {
      margin: 0;
    }
  }
`;

const ContactForm = styled.form`
  width: 100%;
  margin: 2rem 0 0;

  label {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin: 0;
    cursor: pointer;

    small {
      color: var(--brand);
    }
  }
  input,
  textarea {
    width: 100%;
    margin: 0 0 2rem;
    padding: 0.25rem 0.5rem;
  }

  textarea {
    min-height: 200px;
  }

  .form-id,
  .info {
    display: none;
  }

  button {
    background: var(--brand);
    color: white;
    font-weight: bold;
    border: none;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

enum FormSubmitStates {
  READY = "ready",
  SUCCESS = "success",
  ERROR = "error",
}

const ContactInfo = (siteMetadata: SiteMetadataInterface) => {
  const {
    contact: { github, linkedIn },
  } = siteMetadata;

  const [formState, setFormState] = useState(FormSubmitStates.READY);
  const token = "VUJRYnA5UnNEZjpuRF9mTnVnRWVR";
  const checkStat = +new Date();
  let infoStat;

  if (typeof window !== "undefined") {
    infoStat = btoa(`${checkStat}`);
  }

  const heads = {
    Authorization: `Basic ${token}`,
    "X-Check-Status": `${checkStat}`,
  };

  const actionContact = "https://contact.tkarimdesign.com";

  const handleFormSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const form = ev.target as HTMLFormElement;
    const data = new FormData(form);

    if (data.get("_formId")) {
      setFormState(FormSubmitStates.SUCCESS);
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);

    Object.entries(heads).forEach(([name, value]) => {
      xhr.setRequestHeader(name, value);
    });

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setFormState(FormSubmitStates.SUCCESS);
      } else {
        setFormState(FormSubmitStates.ERROR);
      }
    };
    xhr.send(data);
  };

  return (
    <>
      <ContactContent>
        <p>
          If you want to get in touch, just use the form below or send an email
          to contact[at]tkarimdesign[dot]com. You may also find me on the web at
          the links below.
        </p>
        <ul>
          <li>
            <a href={github} target="_blank">
              {github}
            </a>
          </li>
          <li>
            <a href={linkedIn} target="_blank">
              {linkedIn}
            </a>
          </li>
        </ul>
      </ContactContent>
      {formState === FormSubmitStates.SUCCESS && (
        <p>
          Thanks for contacting me. I will get back to you as soon as possible!
        </p>
      )}
      {formState === FormSubmitStates.READY && (
        <ContactForm
          onSubmit={handleFormSubmit}
          action={actionContact}
          method="POST"
        >
          <label htmlFor="name">
            Name <small>required</small>
          </label>
          <input
            required
            id="name"
            type="text"
            name="name"
            placeholder="Enter your name"
          />
          <label htmlFor="email">
            Email <small>required</small>
          </label>
          <input
            required
            id="email"
            type="email"
            name="_replyto"
            placeholder="Enter your email address"
          />
          <label htmlFor="message">
            Message <small>required</small>
          </label>
          <textarea
            required
            id="message"
            name="message"
            placeholder="Questions or comments"
          ></textarea>
          <input type="text" name="_formId" id="form-id" className="form-id" />
          <input
            type="text"
            name="_info"
            id="info"
            className="info"
            defaultValue={infoStat}
          />
          <button type="submit">Send</button>
        </ContactForm>
      )}
    </>
  );
};

export const ContactPage = (props: QueryData) => (
  <LayoutDefault>
    <Seo title="Contact Me" />
    <GridContainer>
      <GridRow>
        <BreadCrumbs></BreadCrumbs>
        <h1>Say Hello!</h1>
        <ContentSection>
          {ContactInfo(props.data.site.siteMetadata)}
        </ContentSection>
      </GridRow>
    </GridContainer>
  </LayoutDefault>
);

export const query = graphql`
  query ContactPageQuery {
    ...siteMetadata
  }
`;

export default ContactPage;

import React from "react";
import styled, { ThemeProps } from "styled-components";

import { ContentLayoutThemeInterface } from "../content-layout/types";
import { TextBlockInterface } from "./types";
import { ContentHeadingBlock } from "../heading-block";

export const ContentSection = styled.section`
  position: relative;
  word-break: break-word;
  grid-column: ${(props: ThemeProps<ContentLayoutThemeInterface>) =>
    props.theme.gridColumnDesktop};

  p {
    margin-top: 2rem;

    code {
      background: #f8f8f8;
      padding: 0.125rem 0.5rem;
      color: #333;
      border-radius: 3px;
      font-size: 1rem;
    }
  }

  ${ContentHeadingBlock} + & {
    p {
      margin-top: 1rem;
    }
  }

  ul {
    margin-top: 1rem;
  }

  li {
    margin-left: 1.6rem;
  }
`;

export const TextBlock: React.FunctionComponent<TextBlockInterface> = (
  props: TextBlockInterface
) => {
  const { richTextContent } = props;

  return (
    <ContentSection
      dangerouslySetInnerHTML={{
        __html: richTextContent,
      }}
    ></ContentSection>
  );
};

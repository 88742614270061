import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { GridRowInterface } from "./types";
import { GridArea } from "../container/types";

const StyledGridRow = styled.div`
  position: relative;
  grid-column: ${(props) =>
    props.theme.gridColumn
      ? props.theme.gridColumn
      : `${props.theme.gridColumnStart} / ${props.theme.gridColumnEnd}`};
`;

export const GridRow: React.FunctionComponent<GridRowInterface> = (
  props: GridRowInterface
) => {
  const { children, gridColumn, gridColumnStart, gridColumnEnd } = props;
  return (
    <ThemeProvider
      theme={{
        gridColumn,
        gridColumnStart,
        gridColumnEnd,
      }}
    >
      <StyledGridRow>{children}</StyledGridRow>
    </ThemeProvider>
  );
};

GridRow.defaultProps = {
  gridColumnStart: GridArea.CONTENT,
  gridColumnEnd: GridArea.CONTENT,
};
